<template>
  <validation-observer
    ref="menuSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="menuSaveModal"
      centered
      body-class="position-static"
      :title="isCreated ? 'Thêm menu' : 'Cập nhật menu'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="title">
          <template v-slot:label>
            Tên menu <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tên menu"
            rules="required"
          >
            <b-form-input
              id="title"
              v-model="targetMenu.title"
              name="name"
              placeholder="Nhập tên menu"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="icon">
          <template v-slot:label>
            Biểu tượng <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Biểu tượng"
            rules="required"
          >
            <v-select
              v-model="targetMenu.icon"
              :options="iconOptions"
              input-id="icon"
              :reduce="option => option.label"
            >
              <template #option="{ label }">
                <feather-icon
                  :icon="label"
                  size="20"
                />
                <span class="ml-50 align-middle"> {{ label }}</span>
              </template>

              <template #selected-option="{ label }">
                <feather-icon
                  :icon="label"
                  size="20"
                />
                <span class="ml-50 align-middle"> {{ label }}</span>
              </template>
            </v-select>
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Tag"
          label-for="tag"
        >
          <b-form-input
            id="tag"
            v-model="targetMenu.tag"
            name="tag"
            placeholder="Nhập tag"
          />
        </b-form-group>

        <b-form-group
          label="Màu sắc tag"
          label-for="tagVariant"
        >
          <b-form-input
            id="tagVariant"
            v-model="targetMenu.tagVariant"
            name="tagVariant"
            placeholder="Nhập màu sắc tag"
          />
        </b-form-group>

        <b-form-group label-for="type">
          <template v-slot:label>
            Loại menu <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Loại menu"
            rules="required"
          >
            <v-select
              v-model="targetMenu.type"
              :options="typeOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetMenu.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="orderNo">
          <template v-slot:label>
            Thứ tự <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thứ tự"
            rules="required"
          >
            <b-form-input
              id="orderNo"
              v-model="targetMenu.orderNo"
              name="orderNo"
              placeholder="Nhập thứ tự"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('menuSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { MENU_TYPES, STATUSES } from '@/const/status'

export default {
  name: 'MenuSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    menu: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetMenu: {
        title: '',
        icon: '',
        tag: '',
        tagVariant: '',
        type: 1,
        status: 1,
        orderNo: 1,
      },
      icons: [
        'ActivityIcon',
        'AirplayIcon',
        'AlertCircleIcon',
        'AlertOctagonIcon',
        'AlertTriangleIcon',
        'AlignCenterIcon',
        'AlignJustifyIcon',
        'AlignLeftIcon',
        'AlignRightIcon',
        'AnchorIcon',
        'ApertureIcon',
        'ArchiveIcon',
        'ArrowDownCircleIcon',
        'ArrowDownLeftIcon',
        'ArrowDownRightIcon',
        'ArrowDownIcon',
        'ArrowLeftCircleIcon',
        'ArrowLeftIcon',
        'ArrowRightCircleIcon',
        'ArrowRightIcon',
        'ArrowUpCircleIcon',
        'ArrowUpLeftIcon',
        'ArrowUpRightIcon',
        'ArrowUpIcon',
        'AtSignIcon',
        'AwardIcon',
        'BarChart2Icon',
        'BarChartIcon',
        'BatteryChargingIcon',
        'BatteryIcon',
        'BellOffIcon',
        'BellIcon',
        'BluetoothIcon',
        'BoldIcon',
        'BookOpenIcon',
        'BookIcon',
        'BookmarkIcon',
        'BoxIcon',
        'BriefcaseIcon',
        'CalendarIcon',
        'CameraOffIcon',
        'CameraIcon',
        'CastIcon',
        'CheckCircleIcon',
        'CheckSquareIcon',
        'CheckIcon',
        'ChevronDownIcon',
        'ChevronLeftIcon',
        'ChevronRightIcon',
        'ChevronUpIcon',
        'ChevronsDownIcon',
        'ChevronsLeftIcon',
        'ChevronsRightIcon',
        'ChevronsUpIcon',
        'ChromeIcon',
        'CircleIcon',
        'ClipboardIcon',
        'ClockIcon',
        'CloudDrizzleIcon',
        'CloudLightningIcon',
        'CloudOffIcon',
        'CloudRainIcon',
        'CloudSnowIcon',
        'CloudIcon',
        'CodeIcon',
        'CodepenIcon',
        'CodesandboxIcon',
        'CoffeeIcon',
        'ColumnsIcon',
        'CommandIcon',
        'CompassIcon',
        'CopyIcon',
        'CornerDownLeftIcon',
        'CornerDownRightIcon',
        'CornerLeftDownIcon',
        'CornerLeftUpIcon',
        'CornerRightDownIcon',
        'CornerRightUpIcon',
        'CornerUpLeftIcon',
        'CornerUpRightIcon',
        'CpuIcon',
        'CreditCardIcon',
        'CropIcon',
        'CrosshairIcon',
        'DatabaseIcon',
        'DeleteIcon',
        'DiscIcon',
        'DivideCircleIcon',
        'DivideSquareIcon',
        'DivideIcon',
        'DollarSignIcon',
        'DownloadCloudIcon',
        'DownloadIcon',
        'DribbbleIcon',
        'DropletIcon',
        'Edit2Icon',
        'Edit3Icon',
        'EditIcon',
        'ExternalLinkIcon',
        'EyeOffIcon',
        'EyeIcon',
        'FacebookIcon',
        'FastForwardIcon',
        'FeatherIcon',
        'FigmaIcon',
        'FileMinusIcon',
        'FilePlusIcon',
        'FileTextIcon',
        'FileIcon',
        'FilmIcon',
        'FilterIcon',
        'FlagIcon',
        'FolderMinusIcon',
        'FolderPlusIcon',
        'FolderIcon',
        'FramerIcon',
        'FrownIcon',
        'GiftIcon',
        'GitBranchIcon',
        'GitCommitIcon',
        'GitMergeIcon',
        'GitPullRequestIcon',
        'GithubIcon',
        'GitlabIcon',
        'GlobeIcon',
        'GridIcon',
        'HardDriveIcon',
        'HashIcon',
        'HeadphonesIcon',
        'HeartIcon',
        'HelpCircleIcon',
        'HexagonIcon',
        'HomeIcon',
        'ImageIcon',
        'InboxIcon',
        'InfoIcon',
        'InstagramIcon',
        'ItalicIcon',
        'KeyIcon',
        'LayersIcon',
        'LayoutIcon',
        'LifeBuoyIcon',
        'Link2Icon',
        'LinkIcon',
        'LinkedinIcon',
        'ListIcon',
        'LoaderIcon',
        'LockIcon',
        'LogInIcon',
        'LogOutIcon',
        'MailIcon',
        'MapPinIcon',
        'MapIcon',
        'Maximize2Icon',
        'MaximizeIcon',
        'MehIcon',
        'MenuIcon',
        'MessageCircleIcon',
        'MessageSquareIcon',
        'MicOffIcon',
        'MicIcon',
        'Minimize2Icon',
        'MinimizeIcon',
        'MinusCircleIcon',
        'MinusSquareIcon',
        'MinusIcon',
        'MonitorIcon',
        'MoonIcon',
        'MoreHorizontalIcon',
        'MoreVerticalIcon',
        'MousePointerIcon',
        'MoveIcon',
        'MusicIcon',
        'Navigation2Icon',
        'NavigationIcon',
        'OctagonIcon',
        'PackageIcon',
        'PaperclipIcon',
        'PauseCircleIcon',
        'PauseIcon',
        'PenToolIcon',
        'PercentIcon',
        'PhoneCallIcon',
        'PhoneForwardedIcon',
        'PhoneIncomingIcon',
        'PhoneMissedIcon',
        'PhoneOffIcon',
        'PhoneOutgoingIcon',
        'PhoneIcon',
        'PieChartIcon',
        'PlayCircleIcon',
        'PlayIcon',
        'PlusCircleIcon',
        'PlusSquareIcon',
        'PlusIcon',
        'PocketIcon',
        'PowerIcon',
        'PrinterIcon',
        'RadioIcon',
        'RefreshCcwIcon',
        'RefreshCwIcon',
        'RepeatIcon',
        'RewindIcon',
        'RotateCcwIcon',
        'RotateCwIcon',
        'RssIcon',
        'SaveIcon',
        'ScissorsIcon',
        'SearchIcon',
        'SendIcon',
        'ServerIcon',
        'SettingsIcon',
        'Share2Icon',
        'ShareIcon',
        'ShieldOffIcon',
        'ShieldIcon',
        'ShoppingBagIcon',
        'ShoppingCartIcon',
        'ShuffleIcon',
        'SidebarIcon',
        'SkipBackIcon',
        'SkipForwardIcon',
        'SlackIcon',
        'SlashIcon',
        'SlidersIcon',
        'SmartphoneIcon',
        'SmileIcon',
        'SpeakerIcon',
        'SquareIcon',
        'StarIcon',
        'StopCircleIcon',
        'SunIcon',
        'SunriseIcon',
        'SunsetIcon',
        'TabletIcon',
        'TagIcon',
        'TargetIcon',
        'TerminalIcon',
        'ThermometerIcon',
        'ThumbsDownIcon',
        'ThumbsUpIcon',
        'ToggleLeftIcon',
        'ToggleRightIcon',
        'ToolIcon',
        'Trash2Icon',
        'TrashIcon',
        'TrelloIcon',
        'TrendingDownIcon',
        'TrendingUpIcon',
        'TriangleIcon',
        'TruckIcon',
        'TvIcon',
        'TwitchIcon',
        'TwitterIcon',
        'TypeIcon',
        'UmbrellaIcon',
        'UnderlineIcon',
        'UnlockIcon',
        'UploadCloudIcon',
        'UploadIcon',
        'UserCheckIcon',
        'UserMinusIcon',
        'UserPlusIcon',
        'UserXIcon',
        'UserIcon',
        'UsersIcon',
        'VideoOffIcon',
        'VideoIcon',
        'VoicemailIcon',
        'Volume1Icon',
        'Volume2Icon',
        'VolumeXIcon',
        'VolumeIcon',
        'WatchIcon',
        'WifiOffIcon',
        'WifiIcon',
        'WindIcon',
        'XCircleIcon',
        'XOctagonIcon',
        'XSquareIcon',
        'XIcon',
        'YoutubeIcon',
        'ZapOffIcon',
        'ZapIcon',
        'ZoomInIcon',
        'ZoomOutIcon',
      ],
      required,
    }
  },
  computed: {
    statusOptions() {
      return STATUSES
    },
    isCreated() {
      return !this.menu
    },
    iconOptions() {
      return this.icons.map(icon => ({ label: icon }))
    },
    typeOptions() {
      return MENU_TYPES
    },
  },
  methods: {
    ...mapActions({
      createMenu: 'menu/createMenu',
      updateMenu: 'menu/updateMenu',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.menu) {
        this.targetMenu = { ...this.menu }
      }
    },
    onHide() {
      this.$refs
        .menuSaveFormRef
        .reset()
      this.targetMenu = {
        name: '',
        code: '',
        type: 1,
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .menuSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createMenu(this.targetMenu)
            : await this.updateMenu(this.targetMenu)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('menuSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
